* {
  font-family: 'Arial', sans-serif;
}
.App-container {
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px;
}

.App-name {
  color: yellow;
  font-size: 35px;
  font-weight: bold;
  margin: 0; 
}

.App-title {
  color: yellow;
  font-size: 18px;
}


.line-breaks br {
  line-height: 1.5; 
}

.App-help {
  color: yellow; 
  font-size: 16px; 
}

.App-terminal {
  background-color: #000000;
  color: #fff;
  border: none;
  padding: 10px;
  width: 10%;
  height: 300px;
  margin-top: 20px;
}


html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: black; 
  color: yellow; 
}


.App {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.App-header {
  width: 100%;
  flex-grow: 1; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; 
}


.App-terminal-output {
  width: 110%;
  flex-grow: 1;
  overflow-y: auto;
  background-color: #020000;
  padding: 10px;
  font-family: 'Courier New', Courier, monospace;
  word-wrap: break-word; 
}

.App-terminal-input {
  width: 80%;
  height: 50px; 
  background-color: #000000; 
  color: yellow;
  border: none;
  padding: 10px;
  font-family: 'Courier New', Courier, monospace;
  outline: none;
}


.command-table {
  width: 100%;
  border-collapse: collapse;
}

.command-table, .command-table th, .command-table td {
  border: 1px solid #ddd;
}

.command-table th, .command-table td {
  padding: 8px;
  text-align: left;
}


.top-right-links {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  display: flex;
  gap: 10px; 
}

.top-right-links a {
  color: yellow;
  cursor: pointer;
  font-size: 35px; 
  font-weight: bold; 
  text-decoration: underline; 
}
